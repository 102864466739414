import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import {translate, updateLanguage} from '../../locales/language.jsx';

import UnlockModal from '../unlock/unlockModal.jsx'
import Store from "../../stores";
import {
  WButton,
  WWindow,
  WNav,
  WSimpleValue,
  WText,
  WTitle,
  WWarn,
} from '../pieces'

import {
  CONFIGURE_RETURNED,
  GET_BALANCES,
  GET_BALANCES_RETURNED,
  GET_BALANCES_CONV_RETURNED,
  REBASE,
  REBASE_TRIGGERED,
} from '../../constants'

import Media from 'react-media';

const styles = theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '600px',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: '40px',
    height: '99%',
    position:'absolute',
    top:0,
  },
  rewardPoolContainerButton: {
    display: 'block',
    margin: 'auto',
    width: '100px',
  },
  rewardPools: {
    display: 'flex',
    paddingTop: '20px',
    flexWrap: 'wrap',
    width: '1000px',
    height: '100px',
    marginTop: '60px',
    position: 'absolute',
  },
  rewardPoolsSmall: {
    display: 'flex',
    paddingTop: '20px',
    flexWrap: 'wrap',
    justifyContent: "center",
    align: "center",
  },
  poolWindow: {
    display: 'block',
    position: 'static',

    "&:nth-child(even)": {
      float: 'right',
      marginLeft: '20px',
    },
    "&:nth-child(odd)": {
      marginRight: '20px',
      float: 'left',
    },
  },
  poolWindowSmall: {
    display: 'block',
    position: 'static',
  },
  boxText: {
    marginLeft: '15px',
  },
  anchor:{
    color:'#296588',
    "&:visited":{
      color:'#296588',
    }
  },
  noWarnSpacer: {
    height: "38px",
  },
  rebaseButton: {
    display: 'block',
    margin: 'auto',
  }
})

const {emitter,dispatcher,store} = Store

class RewardPools extends Component {

  constructor(props) {
    super()

    const account = store.getStore('account')
    const language = store.getStore('language')
    const rewardPools = (language === 'en' ? store.getStore('rewardPools') : store.getStore('rewardPools_chinese'))
    const converters = (language === 'en' ? store.getStore('converters') : store.getStore('converters_chinese'))


    this.state = {
      rewardPools: rewardPools,
      loading: !(account && rewardPools),
      account: account,
      converters: converters,
    }

    dispatcher.dispatch({ type: GET_BALANCES, content: {} })
  }

  componentWillMount() {
    emitter.on(CONFIGURE_RETURNED, this.configureReturned);
    emitter.on(GET_BALANCES_RETURNED, this.balancesReturned);
    emitter.on(GET_BALANCES_CONV_RETURNED, this.balancesConvReturned);
  }

  componentWillUnmount() {
    emitter.removeListener(CONFIGURE_RETURNED, this.configureReturned);
    emitter.removeListener(GET_BALANCES_RETURNED, this.balancesReturned);
    emitter.removeListener(GET_BALANCES_CONV_RETURNED, this.balancesConvReturned);
  }

  balancesReturned = () => {
    let language = store.getStore('language')
    let rewardPools = (language === 'en' ? store.getStore('rewardPools') : store.getStore('rewardPools_chinese'))
    this.setState({ rewardPools: rewardPools })
  }

  balancesConvReturned = () => {
     const converters = store.getStore('converters')
    this.setState({ converters: converters })
  }

  configureReturned = () => {
    this.setState({ loading: false })
  }

  render() {
    const { classes } = this.props;
    const { account, modalOpen } = this.state

    return (
      <div className={ classes.root }>
        
        <Media queries={{
          small: "(max-width: 799px)",
          large: "(min-width: 800px)"
        }}>
          {matches => (
            <Fragment>
              {matches.small && this.renderNav(true)}
              {matches.large && this.renderNav(false)}
              {matches.small && this._render(true)}
              {matches.large && this._render(false)}
            </Fragment>
          )}
        </Media>
        
        { modalOpen && this.renderModal() }
        
      </div>
    )
  }

  _lang() {
    updateLanguage()
    let language = store.getStore('language')
    let rewardPools = (language === 'en' ? store.getStore('rewardPools') : store.getStore('rewardPools_chinese'))
    let converters = (language === 'en' ? store.getStore('converters') : store.getStore('converters_chinese'))
    this.setState({rewardPools: rewardPools, converters: converters })
    this.forceUpdate()
  }

  renderNav(isSmall) {
    const { account} = this.state
    return (<WNav language={this._lang.bind(this)} isMobile={isSmall} account={account} openWallet={this.overlayClicked}/>)
  }
  _render(isSmall) {
    const { classes } = this.props;
    if (isSmall) {
      return(<div className={ classes.rewardPoolsSmall }>
        { this.renderRewards(isSmall) }
        { this.renderConverters(isSmall) }
        { this.renderRebaseButton(isSmall) }
      </div>)
    }
    return(<div className={ classes.rewardPools }>
      { this.renderRewards(isSmall) }
      { this.renderConverters(isSmall) }
      { this.renderRebaseButton(isSmall) }
    </div>)
    
  }
  renderConverters = (isSmall) => {
    const {converters} = this.state
    return converters.map((conv) => {
      return this.renderConverter(conv,isSmall)
    })
  }

  renderRewards = (isSmall) => {
    const { rewardPools } = this.state

    return rewardPools.map((rewardPool) => {
      return this.renderRewardPool(rewardPool,isSmall)
    })
  }
  renderConverter = (burner,isSmall) => {
    const { classes } = this.props

    var address = null;
    if (burner.tokens && burner.tokens[0]) {
      let addy = burner.tokens[0].address
      address = addy.substring(0,6)+'...'+addy.substring(addy.length-4,addy.length)
    }
    var address2 = null;
    if (burner.tokens && burner.tokens[1]) {
      let addy = burner.tokens[1].address
      address2 = addy.substring(0,6)+'...'+addy.substring(addy.length-4,addy.length)
    }
    return (
      <WWindow
        isMobile={ isSmall }
        className={ (isSmall? classes.poolWindowSmall : classes.poolWindow) }
        width={ (isSmall ? "80vw":"470px") }
        title={ burner.title }
        key= { burner.id }>
        <WTitle>{ burner.name }</WTitle>
        <WSimpleValue className={classes.boxText} label={"Based "+translate("RewardPools.ContractAddress")}>
          <a className={classes.anchor} rel="noopener noreferrer" href={ 'https://etherscan.io/address/'+burner.tokens[0].address } target="_blank">{ address }</a>
        </WSimpleValue>
        <WSimpleValue className={classes.boxText} label={"Based V1.5 "+translate("RewardPools.ContractAddress")}>
          <a className={classes.anchor} rel="noopener noreferrer" href={ 'https://etherscan.io/address/'+burner.tokens[1].address } target="_blank">{ address2 }</a>
        </WSimpleValue>
        <WSimpleValue className={classes.boxText} label={translate("RewardPools.Website")}>
          <a className={classes.anchor} rel="noopener noreferrer" href={ burner.link } target="_blank">{burner.website}</a>
        </WSimpleValue>

        { burner.purpose !== undefined && (
          <WText className={classes.boxText}><b>{burner.purpose}</b></WText>)  }

          <div className={classes.rpButtonHodler + "d-flex mt-3"}>
          <WButton value={((burner.cta === undefined)? "📺 View":burner.cta)} className={classes.rewardPoolContainerButton} onClick={() => { if(burner.tokens.length > 0) { this.navigateConvert(burner) } }}/>
          </div>
        {this.renderWarning(burner)}
      </WWindow>)
  }
  renderRewardPool = (rewardPool,isSmall) => {

    const { classes } = this.props

    var address = null;
    let addy = ''
    if (rewardPool.tokens && rewardPool.tokens[0]) {
      addy = rewardPool.tokens[0].rewardsAddress
      address = addy.substring(0,6)+'...'+addy.substring(addy.length-4,addy.length)
    }
    return (
      <WWindow
        isMobile={isSmall}
        className={(isSmall? classes.poolWindowSmall : classes.poolWindow)}
        width={(isSmall ? "80vw":"470px")}
        title={translate("RewardPools.Pool")+ "" + rewardPool.index + (rewardPool.live? "" : translate("RewardPools.NotLive")) }
        key={ rewardPool.id }>
        <WTitle>{rewardPool.name}</WTitle>
        <WSimpleValue className={classes.boxText} label={translate("RewardPools.ContractAddress")}>
          <a className={classes.anchor} rel="noopener noreferrer" href={ 'https://etherscan.io/address/'+addy } target="_blank">{ address }</a>
        </WSimpleValue>
        <WSimpleValue className={classes.boxText} label={translate("RewardPools.Website")}>
          <a className={classes.anchor} rel="noopener noreferrer" href={ rewardPool.link } target="_blank">{rewardPool.website}</a>
        </WSimpleValue>
        { rewardPool.totalAmount !== undefined && (
          <WSimpleValue className={classes.boxText} label={translate("RewardPools.TotalAmount")}>{rewardPool.totalAmount}</WSimpleValue>)  }
        
        { rewardPool.halvingPeriod !== undefined && (
          <WSimpleValue className={classes.boxText}  label={translate("RewardPools.HalvingPeriod")}>{rewardPool.halvingPeriod}</WSimpleValue>)  }
       
        { rewardPool.firstPeriod !== undefined && (
          <WSimpleValue className={classes.boxText}  label={translate("RewardPools.FirstPeriod")}>{rewardPool.firstPeriod}</WSimpleValue>)  }

        { rewardPool.purpose !== undefined && (
          <WText className={classes.boxText}><b>{rewardPool.purpose}</b></WText>)  }

        <WText align="center">{translate("RewardPools.StakeAssestQ")}</WText>
          <div className={classes.rpButtonHodler + "d-flex mt-3"}>
          <WButton value={((rewardPool.cta === undefined)? "📺 View":rewardPool.cta)} className={classes.rewardPoolContainerButton} onClick={() => { if(rewardPool.tokens.length > 0) { this.navigateStake(rewardPool) } }}/>
          </div>
        {this.renderWarning(rewardPool)}
      </WWindow>)
  }
  
  renderRebaseButton = (isSmall) => {

    const { classes } = this.props
    return (
      <WWindow
        isMobile={isSmall}
        className={(isSmall? classes.poolWindowSmall : classes.poolWindow)}
        width={(isSmall ? "80vw":"470px")}
        height={(isSmall ? "210px":"210px")}
        title="Rebase"
        key="rebase">
        <WTitle>FIRE THE REBASE</WTitle>
          <div className={classes.rebaseButtonHolder + "d-flex mt-3"}>
          <WButton value="REBASE" className={classes.rebaseButton} onClick={() => { this.onRebase() }}/>
          </div>
      </WWindow>)
  }
  navigateStake = (rewardPool) => {
    store.setStore({ currentPool: rewardPool })

    this.props.history.push('/stake')
  }

  navigateConvert = (converter) => {
    store.setStore({ converter: converter })
    this.props.history.push('/convert')
  }

  renderWarning = (rewardPool) => {
    const { classes } = this.props

    if (rewardPool.warning !== undefined) {
      return (<WWarn link={rewardPool.warningLink} align="center">{rewardPool.warning}</WWarn>)
    }
    return (<div className={classes.noWarnSpacer}> </div>)
     
  }
  onRebase = () => {
    dispatcher.dispatch({
      type: REBASE,
      content: {},
    });
  }

  renderModal = () => {
    return (
      <UnlockModal closeModal={ this.closeModal } modalOpen={ this.state.modalOpen } />
    )
  }

  overlayClicked = () => {
    this.setState({ modalOpen: true })
  }

  closeModal = () => {
    this.setState({ modalOpen: false })
  }

}

export default withRouter(withStyles(styles)(RewardPools));
