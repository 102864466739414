import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  text: {
    fontSize: '1.1rem'
  },
});

class WText extends Component {
  constructor(props) {
    super();
  }

  componentWillMount() {
  }

  componentWillUnmount() {
  }

  render() {
    const { classes, className } = this.props;
    let {align} = this.props;
    if( align === undefined) {
      align = "inherit"
    }
    return (
      <p style={{textAlign:align}}className={(className === undefined ? "" : className + " ") + classes.text + " card-text"}>
         {this.props.children}
      </p>
    );
  }

}

export default withStyles(styles)(WText);
