import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Draggable from 'react-draggable'; 

const styles = (theme) => ({
  windowHeaderText: {
    fontSize: '20px',
    
  },
  windowHeader: {
    textAlign: 'center',
    borderBottom: '10px solid #296588',
    fontWeight: 700,
    padding: '3px 0 3px 0',
    backgroundColor: 'rgb(227, 253, 255)'
  },
  windowBody: {
    //minWidth: '250px',
    padding: '5px 10px 20px 10px',
    color: 'rgb(41, 101, 136) !important',
    fontWeight: 500,
  },
  window: {
    "position":"absolute",
    "&:active": {
      cursor: 'grabbing',
    },
    fontFamily: "'Roboto Mono',monospace",
    color: '#296588',
    marginTop: '6vh',
    border: '10px solid #296588',
    backgroundColor: 'white',
    borderRadius: '2px',
    boxShadow: '#555 0px 2px 3px 0px',
    overflow: 'wrap',
  },
  xbutton:{
    float: 'right',
    borderRadius: '13px',
    width: '26px',
    height:'26px',
    padding: '4px 2px',
    backgroundColor: 'white',
    margin: '2px 3px 0 0',
    textAlign:'center',
    fontWeight: "900",
    "&:hover": {
      cursor:'pointer',
    },
    border: '2px solid #296588',
    
  },
  xImg: {
    width: '12px',
    height: '12px',
    margin: 'auto',
  }
});

class WWindow extends Component {
  constructor(props) {
    super();
    this.state = {
      "closed": false,
    }
    
  }

  componentDidMount() {
    switch (Math.floor((Math.random() * 4))) {
      case 0:
        this.setState({color:"rgb(233, 255, 231)"})
        break
      case 1:
        this.setState({color:"rgb(255, 251, 220)"})
        break
      case 2:
        this.setState({color:"rgb(255, 223, 177)"})
        break
      case 3:
        this.setState({color:"rgb(242, 231, 255)"})
        break
      default:
    }
  }

  componentWillUnmount() {}

  _renderWindow() {
    const { classes, title, key, className, width, height } = this.props;
    let mWidth = (width === undefined) ? '25vw' : width
    let mHeight = (height === undefined) ? "" : height
    return(<div style={{"width":mWidth, "height":mHeight}} className={ (className === undefined? "" : className + " ") + classes.window} key={ key } >
        <div className= { classes.windowHeader}>
            <span className={ classes.windowHeaderText }>{ title }</span>
            {this.renderX()}
        </div>
        <div style={{backgroundColor:this.state.color}} className={classes.windowBody} >
            {this.props.children}
        </div>
      </div>)
  }
  render() {
    if(this.state.closed) {
      return <div></div>
    }
    const { isMobile } = this.props;
    
    if(isMobile) {
      return this._renderWindow()
    }
    return (
      <Draggable>
        {this._renderWindow()}
      </Draggable>
    );
  }
  defaultClose() {
    this.setState({ closed: true})
  }ΧX
  
  renderX(){
    const { classes, onClose, afterClose } = this.props;

    return (<button className={classes.xbutton} onClick={()=>{
      if(onClose !== undefined) {
        onClose()
      }else{
        this.defaultClose()
      }
      if(afterClose !== undefined) {
        afterClose()
      }
    }}><img className={classes.xImg} src="/x.png" alt="x" /></button>)
  }
}

export default withStyles(styles)(WWindow);
