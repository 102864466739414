import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  windowsButton: {
    transition: 'all 300ms ease 0ms',
    "&:hover": {
      cursor:'pointer',
      boxShadow: 'rgb(21, 113, 150) 0px 4px 0px 1px',
    },
    margin: '5px',
    height: '40px',
    letterSpacing: '.025em',
    padding: '.4rem 1.6rem',
    maxHeight: '40px',
    backgroundColor: 'rgb(247, 172, 178)',
    borderRadius: '50px',
    borderWidth: '0px !important',
    boxShadow: 'rgb(21, 113, 150) 0px 6px 0px 1px',
    color: 'rgb(21, 113, 150)',

  },
  btnText: {
    fontStyle: 'normal',
    fontWeight: 600,
    textShadow: 'none',
    fontFamily: '"Roboto Mono", monospace !important',
  }
});

class WButton extends Component {
  constructor(props) {
    super();
  }

  componentWillMount() {
  }

  componentWillUnmount() {
  }


  render() {
    const { classes, onClick, value, width, fontSize } = this.props;
    var mWidth = "190px"
    if(width !== undefined) {
        mWidth = width
    }
    var mFontSize = "0.9rem"
    if(fontSize !== undefined) {
        mFontSize = fontSize
    }
    return (
      <button
          style= {{width:mWidth, fontSize:mFontSize}}
            className={this.props.className + " "+classes.windowsButton}
            type="button"
            onClick={onClick}
          >
            <span className={classes.btnText}>{value}</span>
          </button>
    );
  }

}

export default withStyles(styles)(WButton);
