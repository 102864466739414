import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import {translate, updateLanguage} from '../../locales/language.jsx';

import WButton from './button'
const styles = (theme) => ({
  liSpacer: {
    marginLeft: '146px'
  },
  superNav:{
    color: 'rgb(41, 101, 136) !important',
    width: "100vw",
    position: 'absolute',
    margin:0,
    top: 0,
    minHeight: '60px',
    backgroundColor: 'white',
    paddingBotton: '30px',
    borderBottom: '10px solid #296588',
  },
  itemHolder: {
    margin: 0,
    padding: '4px 0 4px 0',
    listStyleType: 'none',
  },
  buttonHolder:{
    position: 'absolute',
    right: "30px",
    top: "7px",
    marginLeft: '20px',
  },
  navButtonCommon: {
    height: "30px",
    margin:0,
    borderWidth:"1px",
    "&:hover": {
      borderStyle:'inset',
      cursor:'pointer',
    },

  },
  timeHolder:{
    position: 'absolute',
    top:'8px',
    right: '10px',

  },
  navText:{
    fontSize:"16px",
  },
  superWarning: {
    position:'absolute',
    left: '10%',
    top: '14px',
    marginLeft:'20px',
    fontWeight: 1000,
  },
  
  homeHolder: {
    position: 'absolute',
    top: '7px',
    left: '5px',
    padding: '5px 5px 0 5px',
    backgroundColor: 'rgb(247, 172, 178)',
    boxShadow: 'rgb(21, 113, 150) 0px 4px 0px 1px',
    borderRadius: '30px',
    transition: 'all 300ms ease 0ms',
    "&:hover": {
      cursor:'pointer',
      boxShadow: 'rgb(21, 113, 150) 0px 2px 0px 1px',
    },
  },
  langHolder: {
    position: 'absolute',
    top: '7px',
    left: '60px',
    padding: '5px 5px 0 5px',
    backgroundColor: 'rgb(247, 172, 178)',
    boxShadow: 'rgb(21, 113, 150) 0px 4px 0px 1px',
    borderRadius: '30px',
    transition: 'all 300ms ease 0ms',
    "&:hover": {
      cursor:'pointer',
      boxShadow: 'rgb(21, 113, 150) 0px 2px 0px 1px',
    },
  },
  homeIcon: {
    height: "21px",
    color: 'rgb(41, 101, 136) !important',
    backgroundColor: 'none',
  },
  homeButton: {
    "&:hover": {
      cursor:'pointer',
    },
    background: 'none',
    border: 'none',
  },
  warningMobile: {
    width: '90%',
    display: 'block',
    margin: 0,
    margin: 'auto',
    fontWeight: 1000,
    fontSize: '1rem',
    textAlign: 'center',
  },
  navButtonMobile: {
    
  },
  warningMobileDiv: {
    position: 'absolute',
    display: 'block',
    top: '60px',
    width: '100vw',
    height: '20px',
  }
});

class WNav extends Component {
  constructor(props) {
    super();
  }

  render() {
    const { classes, className, openWallet, account, language, isMobile } = this.props;
    

    return (
      <Fragment>
      {!isMobile && this.renderNormal()}
      {isMobile && this.renderMobile()}
      </Fragment>
    );
  }

  renderNormal() {
    const { classes, className, openWallet, account } = this.props;
    let address = this.getAddress()
    return (
      <nav className={className + " "+classes.superNav}>
          <ul className={classes.itemHolder }>
            {this.renderHomeButton()}
            {this.renderLanguageButton()}
            <li className={classes.superWarning + " nav-item"}>
              <span className={classes.navText +  " nav-link-inner-text"}>{translate("Nav.Degen")}</span>
            </li>
            {this.renderWalletButton(address)}
            
          </ul>
        </nav>)
  }

  renderMobile() {
    const { classes, className, openWallet, account } = this.props;
    let address = this.getAddress()
    return(
      <Fragment>
      <nav className={className + " "+classes.superNav}>
          <ul className={classes.itemHolder }>
            {this.renderHomeButton()}
            {this.renderLanguageButton()}
            {this.renderWalletButton(address, true)}
            
          </ul>
      </nav>
      <div className={classes.warningMobileDiv}>
        <span className={classes.warningMobile +  " nav-link-inner-text"}>{translate("Nav.Degen")}</span>
      </div>

      </Fragment>
    )
  }

  renderHomeButton() {
    const { classes } = this.props;
    return(
      <li className={classes.homeHolder + " nav-item"}>
        <a href="https://based.money">
          <button className={classes.homeButton + " nav-link"} >
            <img alt="home" className={classes.homeIcon} src='/home.png'/>
          </button>
        </a>
      </li>)
  }


  renderLanguageButton() {
    const { classes } = this.props;
    return(
      <li className={classes.langHolder + " nav-item"}>
        {/* <a href="https://based.money"> */}
          <button className={classes.homeButton + " nav-link"} onClick={() => this.props.language()}>
            {/* <img alt="home" className={classes.homeIcon} src='/home.png'/> */}
            中文
          </button>
        {/* </a> */}
      </li>)
  }

  renderWalletButton(address, isSmall) {
    const { classes, openWallet } = this.props;
    return(
      <li className={classes.buttonHolder + " " +classes.liSpacer + " nav-item"}>
        <WButton
          width={(isSmall? '60vw' :'300px')}
          fontSize= {(isSmall? '0.7rem' :'0.9rem')}
          className={(isSmall? classes.navButtonMobile : classes.navButton )+" "+classes.navButtonCommon }
          onClick={openWallet}
          value={ "Wallet " + address }/>
      </li>)
  }

  getAddress() {
    const { account } = this.props;
    let address = " is not connected";
    if (account !== undefined && account.address) {
      address =
        account.address.substring(0, 6) +
        "..." +
        account.address.substring(
          account.address.length - 4,
          account.address.length
        );
    }
    return address
  }

}

export default withStyles(styles)(WNav);
