import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import {translate, updateLanguage} from '../../locales/language.jsx';

import Loader from "../loader";
import Store from "../../stores";

import {
  WBigText,
  WNav,
  WWindow,
  WButton,
  WError,
} from "../pieces"

import Media from 'react-media';
import UnlockModal from '../unlock/unlockModal.jsx'


import {
  ERROR,
  STAKE,
  STAKE_RETURNED,
  WITHDRAW,
  WITHDRAW_RETURNED,
  GET_REWARDS,
  GET_REWARDS_RETURNED,
  EXIT,
  EXIT_RETURNED,
  GET_BALANCES_RETURNED,
} from "../../constants";

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    maxWidth: "900px",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "80px",
  },
  overviewField: {
    display: "flex",
    flexDirection: "column",
  },
  overviewTitle: {
    marginLeft: '10px'
  },
  overviewValue: {
    color: '#888'
  },
  valContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  actionInput: {
    padding: "0px 0px 12px 0px",
    fontSize: "0.5rem",
    borderRadius: '0',
  },
  inputAdornment: {
    fontWeight: "600",
    fontSize: "1.5rem",
    color: "#296588 !important"
  },
  assetIcon: {
    display: "inline-block",
    verticalAlign: "middle",
    borderRadius: "25px",
    background: "#dedede",
    height: "30px",
    width: "30px",
    textAlign: "center",
    marginRight: "16px",
  },
  balances: {
    width: "100%",
    textAlign: "right",
    paddingRight: "20px",
    cursor: "pointer",
  },
  stakeButtons: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    align: "center",
    marginTop: "20px",
  },
  stakeButton: {
    minWidth: "300px",
  },
  popUPWindow: {
    marginTop: "2vh",
    minWidth: "820px",
  },
  popUPWindowMobile: {
    marginTop: "2vh",
  },
  actionButton: {
    float: 'left',
  },
  actionButtonMobile: {
    margin: 'auto',
    display: 'block',
    marginTop:'3vh',
  },
  superButtonHolder: {
    columnCount: 4,
    marginTop:'20px',
  },
  superButtonHolderMobile: {
    columnCount: 1,
    marginTop:'20px',
  },
  popUp: {
    position: 'absolute',
    zIndex: '100',
  },
  popUpMobile: {
    position: 'fixed',
    top: 0,
    zIndex: '100',
  },
  poolWindow: {
    display: 'block',
    position: 'static',
  },
  poolWindowSmall: {
    display: 'block',
    position: 'static',
  },
});

const {emitter,dispatcher,store} = Store

class Stake extends Component {
  constructor(props) {
    super();

    const account = store.getStore("account");
    const pool = store.getStore("currentPool");

    if (!pool) {
      props.history.push("/");
    }

    this.state = {
      pool: pool,
      loading: !(account || pool),
      account: account,
      value: "options",
      balanceValid: false,
    };
  }

  componentWillMount() {
    emitter.on(ERROR, this.errorReturned);
    emitter.on(STAKE_RETURNED, this.showHash);
    emitter.on(WITHDRAW_RETURNED, this.showHash);
    emitter.on(EXIT_RETURNED, this.showHash);
    emitter.on(GET_REWARDS_RETURNED, this.showHash);
    emitter.on(GET_BALANCES_RETURNED, this.balancesReturned);
  }

  componentWillUnmount() {
    emitter.removeListener(ERROR, this.errorReturned);
    emitter.removeListener(STAKE_RETURNED, this.showHash);
    emitter.removeListener(WITHDRAW_RETURNED, this.showHash);
    emitter.removeListener(EXIT_RETURNED, this.showHash);
    emitter.removeListener(GET_REWARDS_RETURNED, this.showHash);
    emitter.removeListener(GET_BALANCES_RETURNED, this.balancesReturned);
  }

  balancesReturned = () => {
    const currentPool = store.getStore("currentPool");
    const pools = store.getStore("rewardPools");
    let newPool = pools.filter((pool) => {
      return pool.id === currentPool.id;
    });

    if (newPool.length > 0) {
      newPool = newPool[0];
      store.setStore({ currentPool: newPool });
    }
  }

  showHash = (txHash) => {
    this.setState({
      snackbarMessage: null,
      snackbarType: null,
      loading: false,
    });
    const that = this;
    setTimeout(() => {
      const snackbarObj = { snackbarMessage: txHash, snackbarType: "Hash" };
      that.setState(snackbarObj);
    });
  }

  errorReturned = (error) => {
    const snackbarObj = { snackbarMessage: null, snackbarType: null };
    this.setState(snackbarObj);
    this.setState({ loading: false });
    const that = this;
    setTimeout(() => {
      const snackbarObj = {
        snackbarMessage: error.toString(),
        snackbarType: "Error",
      };
      that.setState(snackbarObj);
    });
  }
  render() {
    const { classes } = this.props;
    const { account, modalOpen } = this.state

    return (
        <Media queries={{
          small: "(max-width: 799px)",
          large: "(min-width: 800px)"
        }}>
          {matches => (
            <Fragment>
              {matches.small && this._render(true)}
              {matches.large && this._render(false)}
            </Fragment>
          )}
        </Media> 
    )
  }

  _lang() {
    updateLanguage()
    this.forceUpdate()
  }

  _render(isSmall) {
    const { classes } = this.props;
    const {
      value,
      account,
      pool,
      loading,
      snackbarMessage,
      modalOpen
    } = this.state;

    if (!pool) {
      return null;
    }

    return (
      <div className={classes.root}>
        <WNav language={this._lang.bind(this)} isMobile={isSmall} account={account} openWallet={this.overlayClicked}/>

        {(value === "options" || !isSmall) && (<WWindow 
          isMobile={isSmall}
          className={ (isSmall? classes.poolWindowSmall : classes.poolWindow) }
          width={(isSmall)? "80vw" : "1050px"}
          title={this.state.pool.name} onClose={()=>{this.props.history.push("/");}}>
        <WBigText label={translate("Stake.YourBalance")} balance={pool.tokens[0].balance} symbol={pool.tokens[0].symbol} />
        <WBigText label={translate("Stake.CurrentStake")}  balance={pool.tokens[0].stakedBalance} symbol="" />
        <WBigText label={translate("Stake.RewardsAvail")}  balance={pool.tokens[0].rewardsAvailable} symbol={pool.tokens[0].rewardsSymbol} />

        {this.renderOptions(isSmall)}
        
        </WWindow>)}
        {value === "stake" && this.renderStake(isSmall)}
        {value === "claim" && this.renderClaim(isSmall)}
        {value === "unstake" && this.renderUnstake(isSmall)}
        {value === "exit" && this.renderExit(isSmall)}
        {snackbarMessage && this.renderSnackbar(isSmall)}
        {loading && <Loader />}
        { modalOpen && this.renderModal() }
      </div>
    );
  }

  renderOptions = (isSmall) => {
    const { classes } = this.props;

    return (
      <div className={(isSmall? classes.superButtonHolderMobile: classes.superButtonHolder)}>
        <WButton value={translate("Stake.StakeTokens")} className={(isSmall? classes.actionButtonMobile: classes.actionButton)} onClick={() => {this.navigateInternal("stake");}}/>
        <WButton value={translate("Stake.ClaimRewards")} className={(isSmall? classes.actionButtonMobile: classes.actionButton)} onClick={() => {this.onClaim();}}/>
        <WButton value={translate("Stake.UnstakeTokens")} className={(isSmall? classes.actionButtonMobile: classes.actionButton)} onClick={() => {this.navigateInternal("unstake")}}/>
        <WButton width="200px" value={translate("Stake.ClaimAndUnstake")} className={(isSmall? classes.actionButtonMobile: classes.actionButton)} onClick={() => {this.onExit();}}/>
      </div>
    );
  }

  navigateInternal = (val) => {
    this.setState({ value: val });
  }

  renderStake = (isSmall) => {
    const { classes } = this.props;
    const { pool } = this.state;

    const asset = pool.tokens[0];

    return (
      <WWindow
        className={(isSmall? classes.popUPWindowMobile : classes.popUPWindow)}
        width={isSmall? "80vw":"50vw"}
        isMobile={isSmall}
        title={translate("Stake.StakeYourTokens")} onClose={()=>{this.navigateInternal("options")}}>
        {this.renderAssetInput(asset, "stake",isSmall)}
        <div className={classes.stakeButtons}>
          <WButton value={translate("Stake.Stake")} className={classes.actionButton} onClick={() => {this.onStake();}}/>
        </div>
      </WWindow>
    );
  }

  renderUnstake = (isSmall) => {
    const { classes } = this.props;
    const { pool } = this.state;

    const asset = pool.tokens[0];

    return (
       <WWindow 
        className={(isSmall? classes.popUPWindowMobile : classes.popUPWindow)}
        width={(isSmall ? "80vw":"50vw")}
        isMobile={isSmall}
        title={translate("Stake.UnstakeYourTokens")} onClose={()=>{this.navigateInternal("options")}}>
        {this.renderAssetInput(asset, "unstake",isSmall)}
        <div className={classes.stakeButtons}>
          <WButton value={translate("Stake.Unstake")} className={classes.actionButton} onClick={() => {this.onUnstake();}}/>
        </div>
      </WWindow>
    );
  }

  overlayClicked = () => {
    this.setState({ modalOpen: true });
  }

  closeModal = () => {
    this.setState({ modalOpen: false });
  }

  onStake = () => {
    this.setState({ amountError: false });
    const { pool } = this.state;
    const tokens = pool.tokens;
    const selectedToken = tokens[0];
    const amount = this.state[selectedToken.id + "_stake"];

    // if(amount > selectedToken.balance) {
    //   return false
    // }

    this.setState({ loading: true });
    dispatcher.dispatch({
      type: STAKE,
      content: { asset: selectedToken, amount: amount },
    });
  }

  onClaim = () => {
    const { pool } = this.state;
    const tokens = pool.tokens;
    const selectedToken = tokens[0];

    this.setState({ loading: true });
    dispatcher.dispatch({
      type: GET_REWARDS,
      content: { asset: selectedToken },
    });
  }

  onUnstake = () => {
    this.setState({ amountError: false });
    const { pool } = this.state;
    const tokens = pool.tokens;
    const selectedToken = tokens[0];
    const amount = this.state[selectedToken.id + "_unstake"];
    //
    // if(amount > selectedToken.balance) {
    //   return false
    // }

    this.setState({ loading: true });
    dispatcher.dispatch({
      type: WITHDRAW,
      content: { asset: selectedToken, amount: amount },
    });
  }

  onExit = () => {
    const { pool } = this.state;
    const tokens = pool.tokens;
    const selectedToken = tokens[0];

    this.setState({ loading: true });
    dispatcher.dispatch({ type: EXIT, content: { asset: selectedToken } });
  }

  renderAssetInput = (asset, type, isSmall) => {
    const { classes } = this.props;

    const { loading } = this.state;

    const amount = this.state[asset.id + "_" + type];
    const amountError = this.state[asset.id + "_" + type + "_error"];

    return (
      <div className={classes.valContainer} key={asset.id + "_" + type}>
        <div className={classes.balances}>
          {type === "stake" && (
            <Typography
              variant="h4"
              onClick={() => {
                this.setAmount(asset.id, type, asset ? asset.balance : 0);
              }}
              className={classes.value}
              noWrap
            >
              {"Balance: " +
                (asset && asset.balance
                  ? (Math.floor(asset.balance * 10000) / 10000).toFixed(4)
                  : "0.0000")}{" "}
              {asset ? asset.symbol : ""}
            </Typography>
          )}
          {type === "unstake" && (
            <Typography
              variant="h4"
              onClick={() => {
                this.setAmount(asset.id, type, asset ? asset.stakedBalance : 0);
              }}
              className={classes.value}
              noWrap
            >
              {"Balance: " +
                (asset && asset.stakedBalance
                  ? (Math.floor(asset.stakedBalance * 10000) / 10000).toFixed(4)
                  : "0.0000")}{" "}
              {asset ? asset.symbol : ""}
            </Typography>
          )}
        </div>
        <div>
          <TextField
            fullWidth
            disabled={loading}
            className={classes.actionInput}
            id={"" + asset.id + "_" + type}
            value={amount}
            error={amountError}
            onChange={this.onChange}
            placeholder="0.00"
            variant="outlined"
            InputProps={this.getInputProps(asset,isSmall)}
          />
        </div>
      </div>
    );
  }

  getInputProps(asset,isSmall) {
    const { classes } = this.props;
    let out = {
      startAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <div className={classes.assetIcon}>
                    <img
                      alt={asset.id+" logo"}
                      src={"/"+asset.id +"-logo.png"}
                      height="30px"
                    />
                  </div>
                </InputAdornment>
              ),
    }
    if(isSmall) {
      return out
    }
    out.endAdornment =  (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Typography variant="h3" className={""}>
                      {asset.symbol}
                    </Typography>
                  </InputAdornment>
                )
    return out
  }

  renderSnackbar = (isSmall) => {
    const {classes} = this.props;
    var { snackbarType, snackbarMessage } = this.state;
    return (
      <WError
        isMobile={isSmall}
        className={classes.popUp} 
        snackbarMessage={snackbarMessage}
        snackbarType={snackbarType}/>)
  }

  onChange = (event) => {
    let val = [];
    val[event.target.id] = event.target.value;
    this.setState(val);
  }

  setAmount = (id, type, balance) => {
    const bal = (
      Math.floor((balance === "" ? "0" : balance) * 10000) / 10000
    ).toFixed(4);
    let val = [];
    val[id + "_" + type] = bal;
    this.setState(val);
  }
  renderModal = () => {
    return (
      <UnlockModal closeModal={ this.closeModal } modalOpen={ this.state.modalOpen } />
    )
  }
}

export default withRouter(withStyles(styles)(Stake));
