import { InjectedConnector } from "@web3-react/injected-connector";
// import { NetworkConnector } from "@web3-react/network-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { LedgerConnector } from "@web3-react/ledger-connector";
import { TrezorConnector } from "@web3-react/trezor-connector";
import { FrameConnector } from "@web3-react/frame-connector";
import { FortmaticConnector } from "@web3-react/fortmatic-connector";
import { PortisConnector } from "@web3-react/portis-connector";
import { SquarelinkConnector } from "@web3-react/squarelink-connector";
import { TorusConnector } from "@web3-react/torus-connector";
import { AuthereumConnector } from "@web3-react/authereum-connector";
import config from "../config";
const POLLING_INTERVAL = 12000;
const RPC_URLS = {
  1: "https://mainnet.infura.io/v3/036c24b52e9c45239cb0cadbf952decd",
  4: "https://rinkeby.infura.io/v3/036c24b52e9c45239cb0cadbf952decd"
};

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42]
});

// export const network = new NetworkConnector({
//   urls: { 1: RPC_URLS[1], 4: RPC_URLS[4] },
//   defaultChainId: 1,
//   pollingInterval: POLLING_INTERVAL
// });

export const walletconnect = new WalletConnectConnector({
  rpc: { 1: RPC_URLS[1], },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  pollingInterval: POLLING_INTERVAL
});

export const walletlink = new WalletLinkConnector({
  url: RPC_URLS[config.chainID],
  appName: "based.money"
});

export const ledger = new LedgerConnector({
  chainId: config.chainID,
  url: RPC_URLS[config.chainID],
  pollingInterval: POLLING_INTERVAL
});

export const trezor = new TrezorConnector({
  chainId: config.chainID,
  url: RPC_URLS[config.chainID],
  pollingInterval: POLLING_INTERVAL,
  manifestEmail: "dummy@abc.xyz",
  manifestAppUrl: "https://8rg3h.csb.app/"
});

export const frame = new FrameConnector({ supportedChainIds: [config.chainID] });

export const fortmatic = new FortmaticConnector({
  apiKey: "pk_live_175EB05FB59462E4",
  chainId: config.chainID
});

//pk_test_8AB5F6682C0DD3F6

export const portis = new PortisConnector({
  dAppId: "f5efed83-7ef3-429b-8e62-0bffe12fa34c",
  networks: [config.chainID, 100]
});

export const squarelink = new SquarelinkConnector({
  clientId: "5f2a2233db82b06b24f9",
  networks: [config.chainID, 100]
});

export const torus = new TorusConnector({ chainId: config.chainID });

export const authereum = new AuthereumConnector({ chainId: config.chainID });
