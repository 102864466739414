import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  text: {
    fontSize: '0.8rem',
    color: '#cc0000',
    fontWeight: 600,
  },
});

class WWarn extends Component {
  constructor(props) {
    super();
  }

  componentWillMount() {
  }

  componentWillUnmount() {
  }

  render() {
    const { classes , className } = this.props;
    let {align, link} = this.props;
    if( align === undefined) {
      align = "inherit"
    }
    if(link === undefined) {
      link = "#"
    }
    return (
      <a href={link} style={{textAlign:align}} className={(className === undefined ? "" : className + " ") + classes.text + " card-text"}>
         <p>{this.props.children}</p>
      </a>
    );
  }

}

export default withStyles(styles)(WWarn);
