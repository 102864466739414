import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import WText from "./text"
const styles = (theme) => ({
  boldText: {

  }
});

class WSimpleValue extends Component {
  constructor(props) {
    super();
  }

  componentWillMount() {
  }

  componentWillUnmount() {
  }

  render() {
    const { classes, className, label } = this.props;
   
    return (
      <WText className={className}>
         <b className={classes.boldText}> {label}: </b>{this.props.children}
      </WText>
    );
  }
}

export default withStyles(styles)(WSimpleValue);
