import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
} from "@material-ui/core";

const styles = (theme) => ({
  title: {
    textAlign:'center',
    fontSize: '1.5rem',
  },
  overviewField: {
    display: "flex",
    flexDirection: "column",
  },
  overviewTitle: {
    marginLeft: '10px'
  },
  overviewValue: {
    color: '#23bbdd'
  },
});

class WBigText extends Component {
  constructor(props) {
    super();
  }

  componentWillMount() {
  }

  componentWillUnmount() {
  }

  render() {
    const { classes, className, balance, label, symbol } = this.props;
   
    return (
      <div className={(className === undefined ? "" : className + " ") + classes.overviewField}>
          <Typography variant={"h2"} className={classes.overviewTitle}>
             {label}: <span className={classes.overviewValue} > {balance
              ? balance.toFixed(2)
              : "0"}{" "}
            { symbol }
            </span>
          </Typography>
        </div>
    );
  }
}

export default withStyles(styles)(WBigText);
