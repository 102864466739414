import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import WWindow from "./window"

const styles = (theme) => ({
});

class WError extends Component {
  constructor(props) {
    super();
  }
  render() {
    const { className, snackbarType, snackbarMessage, isMobile } = this.props;
    let title = ""
    let msg = ""
    console.log(snackbarMessage)
    switch (snackbarType) {
      case "Error":
        title = "⚠️ Error with your transaction"
        break
      case "Hash":
        title = "Transaction sent successfully"
        msg = "Your transaction hash is:"
        break
      default:
        title = snackbarType
    }
    return (
      <WWindow 
        isMobile={isMobile} 
        width={(isMobile)? '80vw':'900px'} className={className} title={title}>
      {msg !== "" && (<p>{msg}</p>)}
      {snackbarMessage}
      </WWindow>
    );
  }

}

export default withStyles(styles)(WError);
