
export const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    maxWidth: "900px",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "80px",
  },
  overviewField: {
    display: "flex",
    flexDirection: "column",
  },
  overviewTitle: {
    marginLeft: '10px'
  },
  overviewValue: {
    color: '#888'
  },
  valContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },
  actionInput: {
    padding: "0px 0px 12px 0px",
    fontSize: "0.5rem",
    borderRadius: '0',
  },
  inputAdornment: {
    fontWeight: "600",
    fontSize: "1.5rem",
    color: "#296588 !important"
  },
  assetIcon: {
    display: "inline-block",
    verticalAlign: "middle",
    borderRadius: "25px",
    background: "#dedede",
    height: "30px",
    width: "30px",
    textAlign: "center",
    marginRight: "16px",
  },
  balances: {
    width: "100%",
    textAlign: "right",
    paddingRight: "20px",
    cursor: "pointer",
  },
  stakeButtons: {
    width: "100%",
    marginTop: "20px",
  },
  stakeButton: {
    minWidth: "300px",
  },
  popUPWindow: {
    marginTop: "2vh",
    minWidth: "820px",
  },
  popUPWindowMobile: {
    marginTop: "2vh",
  },
  superButtonHolder: {
    marginTop:'20px',
    display: "flex",
    justifyContent: "center",
    align: "center",
  },
  popUp: {
    position: 'absolute',
    zIndex: '100',
  }
});