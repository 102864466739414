import React, { Component, Fragment } from "react";

import {
  WWindow,
} from "../pieces"

import Unlock from './unlock.jsx';
import Media from 'react-media';

class UnlockModal extends Component {

  _render(isSmall){
    const { closeModal/*, modalOpen*/ } = this.props;
    if(isSmall){
      return(
        <WWindow isMobile={isSmall} style={{position:'fixed !important', top:'0px !important'}} width='100%' title={ "Select Your Wallet" } onClose={ closeModal } >
          <Unlock isSmall={isSmall} closeModal={ closeModal } />
        </WWindow>
      )
    }
    else{
      return(
        <WWindow isMobile={isSmall} style={{position:'fixed !important', top:'0px !important'}} width='580px' title={ "Select Your Wallet" } onClose={ closeModal } >
          <Unlock isSmall={isSmall} closeModal={ closeModal } />
        </WWindow>
      )
    }
  }
  render() {
    return (
      <Media queries={{
        small: "(max-width: 799px)",
        large: "(min-width: 800px)"
      }}>
      {matches => (
        <Fragment>
          {matches.small && this._render(true)}
          {matches.large && this._render(false)}
        </Fragment>
      )}
      </Media>   
    )
  };
}
export default UnlockModal;
