import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { colors } from '../../theme'
import {translate, updateLanguage} from '../../locales/language.jsx';

import UnlockModal from '../unlock/unlockModal.jsx'
import WNav from "../pieces/nav";
import WWindow from "../pieces/window";
import WButton from "../pieces/button";
import WTitle from "../pieces/title";

import {
  ERROR,
  CONNECTION_CONNECTED,
  CONNECTION_DISCONNECTED,
  CONFIGURE_RETURNED
} from '../../constants'

import Media from 'react-media';
import Store from "../../stores";
const emitter = Store.emitter
const store = Store.store

const styles = theme => ({
  root: {
    alignItems: 'center',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    background: 'linear-gradient(180deg,rgba(247,172,178,1) 0%,rgba(247,150,113,1) 100%)',
    minWidth: '100vw',
    padding: '36px 24px',
  },
  connectHeading: {
    maxWidth: '300px',
    textAlign: 'center',
    color: colors.white
  },
  connectContainer: {
    padding: '20px'
  },
  actionButton: {
    color: colors.white,
    borderColor: colors.white
  },
  notConnectedRoot: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vw'
  },
  connectedRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%'
  },
  address: {
    color: colors.white,
    width: '100%',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  balances: {
    color: colors.white,
    width: '100%',
    padding: '12px'
  },
  balanceContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  accountHeading: {
    paddingBottom: '6px'
  },
  icon: {
    cursor: 'pointer'
  },
  disclaimer: {
    padding: '12px',
    border: '1px solid '+colors.white,
    borderRadius: '0.75rem',
    marginBottom: '24px',
    fontWeight: 1,
    color: colors.white
  },
  windows: {
    alignItems: 'center',
  },
  buttonHolder:{

  },
  button:{
    display: 'block',
    margin: 'auto',
    width: '100px',
  },
  title:{
    'marginBottom':'35px',
  }
});

class Account extends Component {

  constructor(props) {
    super()

    const account = store.getStore('account')

    this.state = {
      loading: false,
      account: account,
      assets: store.getStore('assets'),
      modalOpen: false,
    }
  }
  componentWillMount() {
    emitter.on(ERROR, this.errorReturned);
    emitter.on(CONNECTION_CONNECTED, this.connectionConnected);
    emitter.on(CONNECTION_DISCONNECTED, this.connectionDisconnected);
    emitter.on(CONFIGURE_RETURNED, this.configureReturned);
  }

  componentWillUnmount() {
    emitter.removeListener(ERROR, this.errorReturned);
    emitter.removeListener(CONNECTION_CONNECTED, this.connectionConnected);
    emitter.removeListener(CONNECTION_DISCONNECTED, this.connectionDisconnected);
    emitter.removeListener(CONFIGURE_RETURNED, this.configureReturned);
  };

  connectionConnected = () => {
    // this.setState({ account: store.getStore('account') })
  };

  configureReturned = () => {
    // this.props.history.push('/')
  }

  connectionDisconnected = () => {
    this.setState({ account: store.getStore('account'), loading: false })
  }

  errorReturned = (error) => {
    //TODO: handle errors
  };

  render() {
    const { classes } = this.props;
    const {
      modalOpen,
    } = this.state

    return (
      <div className={ classes.root }>
      <WNav language={this._lang.bind(this)} openWallet={this.unlockClicked}/>
        { this.renderNotConnected() }
        { modalOpen && this.renderModal() }
      </div>
    )
  };

  _lang() {
    updateLanguage()
    this.forceUpdate()
  }

  _render(isSmall){
    const { classes } = this.props
    if(isSmall){
      return (
        <div className={ classes.notConnectedRoot }>
          <WWindow isMobile={isSmall} width={(isSmall ? '100%' : '650px')} title={translate("Account.WalletConnectErr")} className={classes.windows} onClose={() => {window.location = "https://en.wikipedia.org/wiki/Economics"}}>
            <WTitle className={classes.title}>{translate("Account.ConnectYourWallet")}</WTitle>
            <div className={classes.buttonHolder}>
              <WButton value={translate("Account.Connect")} className={classes.button} onClick={this.unlockClicked}/>
            </div>
          </WWindow>
        </div>
      )
    }
    else{
      return (
        <div className={ classes.notConnectedRoot }>
          <WWindow isMobile={isSmall} width="600px" title={translate("Account.WalletConnectErr")} className={classes.windows} onClose={() => {window.location = "https://en.wikipedia.org/wiki/Economics"}}>
            <WTitle className={classes.title}>{translate("Account.ConnectYourWallet")}</WTitle>
            <div className={classes.buttonHolder}>
              <WButton value={translate("Account.Connect")} className={classes.button} onClick={this.unlockClicked}/>
            </div>
          </WWindow>
        </div>
      )
    }
  }
  renderNotConnected = () => {
    return (
      <Media queries={{
        small: "(max-width: 799px)",
        large: "(min-width: 800px)"
      }}>
      {matches => (
        <Fragment>
          {matches.small && this._render(true)}
          {matches.large && this._render(false)}
        </Fragment>
      )}
      </Media>   
    )

  }

  renderModal = () => {
    return (
      <UnlockModal closeModal={ this.closeModal } modalOpen={ this.state.modalOpen } />
    )
  }

  unlockClicked = () => {
    this.setState({ modalOpen: true, loading: true })
  }

  closeModal = () => {
    this.setState({ modalOpen: false, loading: false })
  }
}

export default withRouter(withStyles(styles)(Account));
