import translationEN from '../locales/en/translation.json';
import translationCN from '../locales/cn/translation.json';
import Store from "../stores";

export const translate = (value) => {
    let language = Store.store.getStore("language");
    if(language === 'en') return translationEN[value]
    else return translationCN[value]
}

export const updateLanguage = () => {
    let language = Store.store.getStore("language");
    console.log(language)
    if(language === 'cn') Store.store.setStore({ language: 'en' });
    else Store.store.setStore({ language: 'cn' });
}