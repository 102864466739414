import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  title: {
    textAlign:'center',
    fontSize: '1.5rem',
  },
});

class WTitle extends Component {
  constructor(props) {
    super();
  }

  componentWillMount() {
  }

  componentWillUnmount() {
  }

  render() {
    const { classes, className } = this.props;
   
    return (
      <h3 className={ (className === undefined ? "" : className + " ") + classes.title}>
      {this.props.children}
      </h3>
    );
  }
}

export default withStyles(styles)(WTitle);
