import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  InputAdornment,
} from "@material-ui/core";

import Loader from "../loader";
import Store from "../../stores";
import {translate, updateLanguage} from '../../locales/language.jsx';

import {
  WBigText,
  WNav,
  WWindow,
  WButton,
  WError,
  WSimpleValue,
} from "../pieces"

import UnlockModal from '../unlock/unlockModal.jsx'
import Media from 'react-media';
import {
  ERROR,
  CONVERT,
  CONVERT_RETURNED,
  GET_BALANCES_RETURNED,
  GET_BALANCES_CONV_RETURNED,
} from "../../constants";

import {styles} from "./style.jsx"

const {emitter,dispatcher,store} = Store

class Convert extends Component {
  constructor(props) {
    super();

    const account = store.getStore("account");
    const converter = store.getStore("converter");

    if (!converter) {
      props.history.push("/");
    }

    this.state = {
      converter: converter,
      loading: !(account || converter),
      account: account,
      value: "options",
      balanceValid: false,
    };
  }

  componentWillMount() {
    emitter.on(ERROR, this.errorReturned);
    emitter.on(CONVERT_RETURNED, this.showHash);
    emitter.on(GET_BALANCES_CONV_RETURNED, this.balancesConvReturned);
  }

  componentWillUnmount() {
    emitter.removeListener(ERROR, this.errorReturned);
    emitter.removeListener(CONVERT_RETURNED, this.showHash);
    emitter.removeListener(GET_BALANCES_CONV_RETURNED, this.balancesConvReturned);
  }

  balancesConvReturned = () => {
    const converter = store.getStore("converter");
    let language = store.getStore("language");
    let pools = (language === 'en' ? store.getStore("converters") :  store.getStore("converters_chinese") )
    let newPool = pools.filter((pool) => {
      return pool.id === converter.id;
    });

    if (newPool.length > 0) {
      newPool = newPool[0];
      store.setStore({ converter: newPool });
    }
  }

  showHash = (txHash) => {
    this.setState({
      snackbarMessage: null,
      snackbarType: null,
      loading: false,
    });
    const that = this;
    setTimeout(() => {
      const snackbarObj = { snackbarMessage: txHash, snackbarType: "Hash" };
      that.setState(snackbarObj);
    });
  }

  errorReturned = (error) => {
    const snackbarObj = { snackbarMessage: null, snackbarType: null };
    this.setState(snackbarObj);
    this.setState({ loading: false });
    const that = this;
    setTimeout(() => {
      const snackbarObj = {
        snackbarMessage: error.toString(),
        snackbarType: "Error",
      };
      that.setState(snackbarObj);
    });
  }

  render() {
    const { classes } = this.props;
    const { account, modalOpen } = this.state

    return (
        <Media queries={{
          small: "(max-width: 799px)",
          large: "(min-width: 800px)"
        }}>
          {matches => (
            <Fragment>
              {matches.small && this._render(true)}
              {matches.large && this._render(false)}
            </Fragment>
          )}
        </Media> 
    )
  }

  _lang() {
    updateLanguage()
    this.forceUpdate()
  }

  _render(isSmall) {
    const { classes } = this.props;
    const {
      value,
      account,
      converter,
      loading,
      snackbarMessage,
      modalOpen
    } = this.state;

    if (!converter) {
      return null;
    }

    return (
      <div className={classes.root}>
        <WNav language={this._lang.bind(this)} isMobile={isSmall} account={account} openWallet={this.overlayClicked}/>

        <WWindow
          isMobile={isSmall}
          width={ isSmall? "80vw" : "1050px"}
          title={this.state.converter.name}
          onClose={()=>{this.props.history.push("/");}}>
          <WBigText label={translate("Convert.Your")+converter.tokens[0].name+translate("Convert.Balance")} balance={converter.tokens[0].balance} symbol={converter.tokens[0].symbol} />
          <WBigText label={translate("Convert.Your")+converter.tokens[1].name+translate("Convert.Balance")} balance={converter.tokens[1].balance} symbol={converter.tokens[1].symbol} />

          {this.renderOptions()}
        
        </WWindow>
        { value === "convert" && this.renderConvert(isSmall) }
        { snackbarMessage && this.renderSnackbar(isSmall) }
        { loading && <Loader /> }
        { modalOpen && this.renderModal(isSmall) }
      </div>
    );
  }

  renderOptions = () => {
    const { classes } = this.props;

    return (
      <div className={classes.superButtonHolder}>

        <WButton value={translate("Convert.Migrate")} className={classes.actionButton} onClick={() => {this.navigateInternal("convert");}}/>
      </div>
    );
  }

  navigateInternal = (val) => {
    this.setState({ value: val });
  }

  // _render(isSmall){
  //   const { classes } = this.props;
  //   const { converter } = this.state;
  //   const asset = converter.tokens[0];

  //   return(
  //     <WWindow
  //     isMobile={isSmall}
  //     className={(isSmall? classes.popUPWindowMobile : classes.popUPWindow)}
  //     width={(isSmall? "80vw" : "50vw")}
  //     title={translate("Convert.MigrateTokens")} onClose={()=>{this.navigateInternal("options")}}>
  //     {this.renderAssetInput(asset, "convert")}
  //     <div className={classes.superButtonHolder}>
  //       <WButton value={translate("Convert.Migrate")} className={classes.actionButton} onClick={() => {this.onConvert();}}/>
  //     </div>
  //   </WWindow>
  //   )
  // }

  renderConvert = (isSmall) => {
    const { classes } = this.props;
    const { converter } = this.state;
    // const tokens = converter.tokens;
    // const selectedToken = tokens[0];
    // const amount = this.state[selectedToken.id + "_stake"];

    const asset = converter.tokens[0];

    return (
      <WWindow
        isMobile={isSmall}
        className={(isSmall? classes.popUPWindowMobile : classes.popUPWindow)}
        width={(isSmall? "80vw" : "50vw")}
        title="Migrate your tokens" onClose={()=>{this.navigateInternal("options")}}>
        {this.renderAssetInput(asset, "convert")}
        <div className={classes.superButtonHolder}>
          <WButton value="Migrate" className={classes.actionButton} onClick={() => {this.onConvert();}}/>
        </div>
      </WWindow>
    );
  }

  overlayClicked = () => {
    this.setState({ modalOpen: true });
  }

  closeModal = () => {
    this.setState({ modalOpen: false });
  }

  onConvert = () => {
    this.setState({ amountError: false });
    const { converter } = this.state;
    const tokens = converter.tokens;
    const selectedToken = tokens[0];
    const amount = this.state[selectedToken.id + "_stake"];

    this.setState({ loading: true });
    dispatcher.dispatch({
      type: CONVERT,
      content: { asset: selectedToken },
    });
  }

  renderAssetInput = (asset, type, isSmall) => {
    const { classes } = this.props;

    const { loading } = this.state;

    const amount = this.state[asset.id + "_" + type];
    const amountError = this.state[asset.id + "_" + type + "_error"];

    return (
      <div className={classes.valContainer} key={asset.id + "_" + type}>
      <WSimpleValue className={classes.boxText} label={translate("Convert.Receive")}>
          {(asset && asset.balance
                  ? (Math.floor(asset.balance * 10000) / 10000).toFixed(4)
                  : "0.0000")}
        </WSimpleValue>
      </div>
    );
  }

  getInputProps(asset, isSmall) {
    const { classes } = this.props;
    let out = {
      startAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <div className={classes.assetIcon}>
                    <img
                      alt={asset.id+" logo"}
                      src={"/"+asset.id +"-logo.png"}
                      height="30px"
                    />
                  </div>
                </InputAdornment>
              ),
    }
    if(isSmall) {
      return out
    }
    out.endAdornment =  (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Typography variant="h3" className={""}>
                      {asset.symbol}
                    </Typography>
                  </InputAdornment>
                )
    return out
  }


  renderSnackbar = (isSmall) => {
    const {classes} = this.props;
    var { snackbarType, snackbarMessage } = this.state;
    return (
      <WError
        isMobile={isSmall}
        className={classes.popUp} 
        snackbarMessage={snackbarMessage}
        snackbarType={snackbarType}/>)
  }

  onChange = (event) => {
    let val = [];
    val[event.target.id] = event.target.value;
    this.setState(val);
  }

  setAmount = (id, type, balance) => {
    const bal = (
      Math.floor((balance === "" ? "0" : balance) * 10000) / 10000
    ).toFixed(4);
    let val = [];
    val[id + "_" + type] = bal;
    this.setState(val);
  }
  renderModal = () => {
    return (
      <UnlockModal closeModal={ this.closeModal } modalOpen={ this.state.modalOpen } />
    )
  }
}

export default withRouter(withStyles(styles)(Convert));
